import Login from "./components/Login";
import Home from "./components/Home";
import Editor from "./components/Editor";
import Admin from "./scenes/admin/users_table";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
import LinkPage from "./components/LinkPage";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import { Routes, Route } from "react-router-dom";
import { Reset } from "./components/ResetPassword";
import { RequestReset } from "./components/RequestReset";
import { useState } from "react";
import Topbar from "./scenes/global/Topbar";
import MySidebar from "./scenes/global/MySidebar";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Dashboard from "./scenes/dashboard";
import Geography from "./scenes/geography";
import UserProfile from "./components/Profile";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ProSidebarProvider } from 'react-pro-sidebar';
import AdminPermissions from "./scenes/admin/users_permissions";

const ROLES = {
  Operator: "operator",
  Admin: "admin",
};

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <MySidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="login" element={<Login />} />
              <Route
                path="reset"
                element={<Reset endpoint="/user/reset_finalize" />}
              />
              <Route path="confirm" element={<Reset endpoint="/user/finalize" />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="request_reset" element={<RequestReset />} />

              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                  <Route path="admin" element={<Admin />} />
                  <Route path="admin/:userId/permissions" element={<AdminPermissions />} />
                </Route>

                <Route
                  element={
                    <RequireAuth allowedRoles={[ROLES.Operator, ROLES.Admin]} />
                  }
                >
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/profile" element={<UserProfile />} />
                  <Route path="editor" element={<Editor />} />
                  <Route path="linkpage" element={<LinkPage />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/form" element={<Form />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/line" element={<Line />} />
                  <Route path="/geography" element={<Geography />} />
                </Route>
                <Route path="/faq" element={<FAQ />} />
              </Route>

              {/* Catch all */}
              <Route path="*" element={<Missing />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
