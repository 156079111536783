import React, { useState, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { validatePassword } from "../utils/validation";
import Swal from "sweetalert2";
import { axiosPublic } from "../api/axios";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

export function Reset({ buttonLabel, endpoint }) {
  const [showMessageError1, setShowMessageError1] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [showMessageError2, setShowMessageError2] = useState(false);
  const [errorMessage2, setErrorMessage2] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [redirect, setRedirect] = useState(false);
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get("token");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  function toggleVisibility1() {
    setShowPassword1(!showPassword1);
  }
  function toggleVisibility2() {
    setShowPassword2(!showPassword2);
  }
  const handlePassword1Change = (e) => {
    setPassword1(e.target.value);
    setErrMsg("");
  };
  const handlePassword2Change = (e) => {
    setPassword2(e.target.value);
    setErrMsg("");
  };

  const onClickSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage1(validatePassword(password1));
    if (errorMessage1.length > 0) {
      setShowMessageError1(true);
    } else {
      setShowMessageError1(false);

      if (password1 !== password2) {
        setErrorMessage2("Passwords do not match!");
        setShowMessageError2(true);
      } else {
        try {
          const response = await axiosPublic.put(
            `${endpoint}`,
            { password: password1, token },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "Access-Control-Allow-Origin": "*",
              },
            }
          );

          const success = response.data.status;

          if (success) {
            Swal.fire({
              position: "center",
              icon: "success",
              titleText: "Your password has been changed!",
              showConfirmButton: false,
              timer: 9000,
            });
            setRedirect(true);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              titleText: "Your password cannot be changed!",
              showConfirmButton: false,
              timer: 5000,
            });
          }
        } catch (err) {
          if (!err?.response) {
            setErrMsg("No Server Response");
          } else if (err.response?.status === 400) {
            setErrMsg("Invalid Token");
          } else {
            setErrMsg("Password Reset Failed");
          }
          errRef.current.focus();
        }

        setErrorMessage2("");
        setShowMessageError2(false);
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
      <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            {buttonLabel}
          </Typography>
          <form onSubmit={onClickSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="New Password"
              type={showPassword1 ? "text" : "password"}
              autoComplete="off"
              autoFocus
              onChange={handlePassword1Change}
              value={password1}
              InputProps={{
                style: {
                  color: colors.greenAccent[600],
                  backgroundColor: colors.primary[400],
                },
              }}
            />
            <Button
              type="button"
              onClick={toggleVisibility1}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "8px 20px",
              }}
            >
              Toggle Visibility
            </Button>
            {showMessageError1 && <div>{errorMessage1}</div>}

            <TextField
              margin="normal"
              required
              fullWidth
              label="Confirm New Password"
              type={showPassword2 ? "text" : "password"}
              autoComplete="off"
              onChange={handlePassword2Change}
              value={password2}
              InputProps={{
                style: {
                  color: colors.greenAccent[600],
                  backgroundColor: colors.primary[400],
                },
              }}
            />
            <Button
              type="button"
              onClick={toggleVisibility2}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "8px 20px",
              }}
            >
              Toggle Visibility
            </Button>
            {showMessageError2 && <div>{errorMessage2}</div>}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginTop: "15px",
              }}
            >
              Submit
            </Button>
            {redirect && <Navigate to="/" />}
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
