import useAuth from './useAuth';
import { axiosPublic } from '../api/axios';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        try {
            const response = await axiosPublic.post('/refresh', {}, {
                withCredentials: true,
            });

            if (response.data.data.logged_in) {
                setAuth(prev => {
                    return {
                        ...prev,
                        roles: localStorage.getItem("user_roles").split(","),
                        user: localStorage.getItem("user"),
                        loggedIn: true,
                    }
                });
                return true;
            } else {
              localStorage.clear()
                setAuth(prev => {
                    return {
                        ...prev,
                        roles: [],
                        user: "",
                        loggedIn: false,
                    }
                });
                return false;
            }
        } catch (error) {
            console.error(error);
            localStorage.clear()
            setAuth(prev => {
                return {
                    ...prev,
                    roles: [],
                    user: "",
                    loggedIn: false,
                }
            });
            return false;
        }
    };

    return refresh;
};

export default useRefreshToken;