/* Inspired by https://github.com/KevinVandy/material-react-table/blob/v1/apps/material-react-table-docs/examples/editing-crud/sandbox/src/JS.js */
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import CreateUserModal from './CreateUserModal';
import SnackbarAlert from './SnackbarAlert';
import {
  Box,
  Button,
  Switch,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { updatedDiff } from "deep-object-diff";
import { validateEmail, validatePhoneNumber, validateUsername, validateRequired } from "../utils/validation";

const UsersTable = () => {
  const axiosPrivate = useAxiosPrivate();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [allSelectedRows, setAllSelectedRows] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const endpoint_users = "/admin/users";
  const endpoint_users_edit = "/admin/edituser";
  const endpoint_pwd_reset = "/user/reset";

  useEffect(() => {
    const controller = new AbortController();
    let isMounted = true;

    const getUsers = async () => {
      isMounted = true;
      try {
        let response = await axiosPrivate.get(
          endpoint_users,
          {},
          {
            signal: controller.signal,
          }
        );
        setTableData(response.data.data.users);
        setLoading(false);
        setIsError(false);
      } catch (err) {
        setLoading(false);
        setIsError(true);
        console.error(err);
        return;
      }
    };

    // Fetch data when the component mounts or when dataChanged is true
    if (!isLoading && (dataChanged || tableData.length === 0)) {
      getUsers();
      setDataChanged(false); // Reset dataChanged to false after fetching data
    }

    // Cleanup to prevent further updates to state
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [isLoading, dataChanged]);


  const handleUserCreated = () => {
    setDataChanged(true);
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    const current_user = { current_user: row.original.username };
    values.is_enabled = Boolean(values.is_enabled);
    tableData[row.index] = values;
    const merged_values = { ...current_user, ...values };
    try {
      const response = await axiosPrivate.put(
        endpoint_users_edit,
        merged_values,
        {}
      );
      setTableData([...tableData]);
      setDataChanged(true);
      setSnackbarMessage(`${response.data.data.message}`);
      setSnackbarOpen(true);
    } catch (err) {
      setSnackbarMessage(
        `Error editing user due to: ${err.response.data.data.message}`
      );
      console.log(err);
      setSnackbarOpen(true);
      setDataChanged(true);
    }

    exitEditingMode();
  };

  const handleToggleSwitchChange = async (rowData, updatedIsEnabled, index) => {
    try {
      rowData.is_enabled = updatedIsEnabled;
      tableData[index] = rowData;
      const merged_values = {
        is_enabled: rowData.is_enabled,
        current_user: rowData.username,
      };
      const response = await axiosPrivate.put(
        endpoint_users_edit,
        merged_values,
        {}
      );

      setTableData([...tableData]);
      setDataChanged(true);
      console.log("Updated rowData:", rowData);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteRow = useCallback(
    // Not implemented
    (row) => {
      if (
        window.confirm(
          `Are you sure you want to delete ${row.getValue("firstname")}`
        )
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === "email"
              ? validateEmail(event.target.value)
              : cell.column.id === "username"
              ? validateUsername(+event.target.value)
              : cell.column.id === "phone_number"
              ? validatePhoneNumber(event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );


  const renderDetailPanel = ({ row }) => {
    const user = row.original;

    return (
      <Box padding={2}>
        <Typography variant="h6">User Details:</Typography>
        <Typography><strong>Full Name:</strong> {user.fullname}</Typography>
        <Typography><strong>Email:</strong> {user.email}</Typography>
        <Typography><strong>Roles:</strong> {user.roles}</Typography>
        <Typography variant="h6" marginTop={2}>Reactors and Permissions:</Typography>
        {user.reactors && user.reactors.map((reactor, index) => (
          <Box key={index} marginY={1}>
            <Typography><strong>Reactor Name:</strong> {reactor.name}</Typography>
            <Typography><strong>Role:</strong> {reactor.role}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnOrdering: true,
        enableEditing: false,
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: "fullname",
        header: "Full Name",
        enableEditing: true,
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "username",
        header: "Username",
        enableEditing: true,
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "email",
        header: "Email",
        enableEditing: true,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "email",
        }),
      },
      {
        accessorKey: "phone_number",
        header: "Phone Number",
        enableEditing: true,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "tel",
        }),
      },
      {
        accessorKey: "roles",
        header: "Roles",
        enableEditing: true,
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "is_enabled",
        header: "Enabled",
        enableEditing: true,
        Cell: ({ cell }) => (
          <Switch
            checked={cell.getValue() === true}
            color="secondary"
            onChange={() =>
              handleToggleSwitchChange(
                cell.row.original,
                !cell.getValue(),
                cell.row.index
              )
            }
          />
        ),
      },
    ],
    [getCommonEditTextFieldProps]
  );

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        renderDetailPanel={renderDetailPanel}
        enableColumnOrdering
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        enableEditing
        enablePinning
        enableGrouping
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: 'Error loading data',
              }
            : undefined
        }
        initialState={{ showColumnFilters: false, sorting: [
          {
            id: 'id',
            desc: false,
          },
         ] }}
        positionToolbarAlertBanner="bottom"
        onEditingRowSave={handleSaveRowEdits}
        state={{
          showAlertBanner: isError,
          isLoading
        }}
        renderTopToolbarCustomActions={({ table }) => {
          const handleReset = () => {
            setSnackbarMessage("");

            table.getSelectedRowModel().flatRows.map(async (row) => {
              try {
                const response = await axiosPrivate.put(
                  endpoint_pwd_reset,
                  { email: row.getValue("email") },
                  {}
                );
                setSnackbarMessage(`${response.data.data.message}`);
                setSnackbarOpen(true);
              } catch (err) {
                setSnackbarMessage(
                  `Error resetting password: ${
                    err.response.data.data.message
                  }`
                );
                setSnackbarOpen(true);
              }
            });
          };

          const handleEditPermissions = () => {
            const selectedRows = table.getSelectedRowModel().flatRows;
            if (selectedRows.length === 1) {
              const userId = selectedRows[0].original.id;
              const userName = selectedRows[0].original.fullname;
              navigate(`/admin/${userId}/permissions`, { state: { userId, userName } });
            } else {
              setSnackbarMessage(
                `Select only one user to modify their permissions.`
              );
              setSnackbarOpen(true);
            }
          };

          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Button
                color="secondary"
                onClick={() => setCreateModalOpen(true)}
                variant="contained"
              >
                Create New Account
              </Button>

              <Button
                color="error"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleReset}
                variant="contained"
              >
                Reset Password
              </Button>

              <Button
                color="error"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleEditPermissions}
                variant="contained"
              >
                Edit Permissions
              </Button>
            </div>
          );
        }}
        onSelectionChange={(rows) => {
          setAllSelectedRows([...rows]);
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              const dataUpdateArray = [...tableData];
              if (allSelectedRows.length && allSelectedRows.includes(oldData)) {
                const updatedData = updatedDiff(oldData, newData);
                allSelectedRows.map((item) => {
                  return Object.assign(
                    dataUpdateArray[item.tableData.id],
                    updatedData
                  );
                });
              } else {
                dataUpdateArray[oldData.tableData.id] = newData;
              }
              setTableData([...dataUpdateArray]);
              resolve();
            }),
        }}
      />
      <CreateUserModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        columns={columns.filter(column => !['id', 'is_enabled'].includes(column.accessorKey))}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
        onUserCreated={handleUserCreated}
      />
      <SnackbarAlert
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};


export default UsersTable;
