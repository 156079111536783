import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import { validateEmail, validatePhoneNumber } from "../utils/validation";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const CreateUserModal = ({
  open,
  onClose,
  columns,
  setSnackbarMessage,
  setSnackbarOpen,
  onUserCreated,
}) => {
  const initialFormValues = columns.reduce((acc, column) => {
    if (!["id", "is_enabled"].includes(column.accessorKey)) {
      acc[column.accessorKey] = "";
    }
    return acc;
  }, {});

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const axiosPrivate = useAxiosPrivate();

  const resetForm = () => {
    setValues(initialFormValues);
    setErrors({});
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    }
  };

  const validateForm = () => {
    let tempErrors = {};
    const invalidEmail = validateEmail(values.email);
    if (invalidEmail) {
      tempErrors.email = invalidEmail;
    }
    if (!validatePhoneNumber(values.phone_number)) {
      tempErrors.phone_number =
        "Invalid phone number format. Must match +[country code][number]";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSnackbarMessage("Please correct the errors before submitting.");
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await axiosPrivate.post("/admin/adduser", values);
      setSnackbarMessage(
        response.data.data.message || "User successfully created."
      );
      setSnackbarOpen(true);
      onClose();
      onUserCreated();
      setValues(initialFormValues);
    } catch (error) {
      const errorMessage =
        error.response?.data?.data.message || "Failed to create user.";
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create New Account</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: "100%",
            minWidth: { xs: "300px", sm: "360px", md: "400px" },
            gap: "1.5rem",
          }}
        >
          {columns.map((column) => {
            if (!["id", "is_enabled"].includes(column.accessorKey)) {
              return (
                <TextField
                  autoFocus
                  key={column.accessorKey}
                  name={column.accessorKey}
                  label={column.header}
                  value={values[column.accessorKey] || ""}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  error={!!errors[column.accessorKey]}
                  helperText={errors[column.accessorKey]}
                />
              );
            }
            return null;
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="secondary" variant="contained">
          Create New Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserModal;
