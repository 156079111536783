import React, { useState, useEffect, useMemo, useCallback } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  Snackbar,
  SnackbarContent,
  IconButton,
} from "@mui/material";
import { Add, Delete, Edit, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ReactorsPermissionsTable = ({ userId }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [availableReactors, setAvailableReactors] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [newRowData, setNewRowData] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const [editingRowId, setEditingRowId] = useState(null);
  const [originalRole, setOriginalRole] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const controller = new AbortController();
    let isMounted = true;

    const getPermissions = async () => {
      isMounted = true;
      try {
        let response = await axiosPrivate.get(`/admin/permissions/${userId}`, {
          signal: controller.signal,
        });
        setTableData(response.data.data.user.reactors);
        setAvailableReactors(response.data.data.available_reactors);
        setLoading(false);
        setIsError(false);
      } catch (err) {
        setIsError(true);
        setLoading(false);
        console.error(err);
        return;
      }
    };
    getPermissions();
    setDataChanged(false);
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [isLoading, dataChanged]);

  const roles = ["operator", "admin"];

  const handleAddRow = () => {
    const firstAvailableReactor =
      filteredAvailableReactors.length > 0
        ? filteredAvailableReactors[0]
        : "No other available reactors";
    setNewRowData({
      id: firstAvailableReactor.id,
      name: firstAvailableReactor.name,
      role: roles[0],
    });
  };

  const handleSaveNewRow = async () => {
    try {
      const response = await axiosPrivate.post(
        `/admin/permissions/${userId}/add`,
        newRowData
      );
      setTableData([...tableData]);
      setAvailableReactors([...availableReactors]);
      setLoading(false);
      setNewRowData(null);
      setDataChanged(true);
    } catch (err) {
      setSnackbarMessage(
        `Error editing user due to: ${err.response.data.data.message}`
      );
      console.log(err);
      setSnackbarOpen(true);
      setDataChanged(true);
    }
  };

  const handleCancelNewRow = () => {
    setNewRowData(null);
  };

  const handleDeleteRow = useCallback(
    async (row) => {
      const confirmDelete = window.confirm(
        `Are you sure you want to delete permissions on Reactor: ${row.name}?`
      );

      if (confirmDelete) {
        try {
          const response = await axiosPrivate.delete(
            `/admin/permissions/${userId}/delete`,
            { data: row }
          );
          setTableData([...tableData]);
          setAvailableReactors([...availableReactors]);
          setLoading(false);
          setNewRowData(null);
          setDataChanged(true);
        } catch (err) {
          setSnackbarMessage(
            `Error editing user due to: ${err.response.data.data.message}`
          );
          console.error("Error deleting permission:", err);
          setSnackbarOpen(true);
        }
      }
    },
    [axiosPrivate, userId]
  );

  const handleEditRow = useCallback((row) => {
    setEditingRow({ ...row });
    setEditingRowId(row.id);
    setOriginalRole(row.role);
  }, []);

  const handleSaveEdit = useCallback(async () => {
    if (!editingRow) return;
    const payload = {
      ...editingRow,
      originalRole: originalRole,
      newRole: editingRow.role,
    };

    try {
      const response = await axiosPrivate.post(
        `/admin/permissions/${userId}/edit`,
        payload
      );
      if (response && response.status === 200) {
        setDataChanged(true);
        const updatedReactor = response.data.data.user.reactors.find(
          (r) => r.id === editingRow.id
        );

        if (updatedReactor) {
          setTableData([...tableData]);
          setAvailableReactors([...availableReactors]);
        } else {
          setSnackbarMessage(`Updated reactor not found in response`);
          setSnackbarOpen(true);
          console.error("Updated reactor not found in response");
        }
      } else {
        // Handle unsuccessful update
        setSnackbarMessage(`Update was not successful`);
        setSnackbarOpen(true);
        console.error("Update was not successful");
      }
    } catch (err) {
      setSnackbarMessage(`Error updating permission due to: ${err}`);
      setSnackbarOpen(true);
      console.error("Error updating permission:", err);
    }

    setEditingRow(null);
    setEditingRowId(null);
    setOriginalRole(null);
  }, [axiosPrivate, userId, editingRow, originalRole]);

  const handleCancelEdit = () => {
    setEditingRow(null);
    setEditingRowId(null);
  };

  const filteredAvailableReactors = useMemo(
    () =>
      availableReactors.filter(
        (reactor) => !tableData.some((row) => row.name === reactor.name)
      ),
    [availableReactors, tableData]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 60,
        Cell: ({ row }) => <Typography>{row.original.id}</Typography>,
      },
      {
        accessorKey: "name",
        header: "Reactor Name",
        size: 180,
        Cell: ({ row }) => <Typography>{row.original.name}</Typography>,
      },
      {
        accessorKey: "role",
        header: "Role",
        size: 150,
        Cell: ({ row }) => {
          if (editingRowId === row.original.id) {
            return (
              <TextField
                select
                value={editingRow.role}
                onChange={(e) =>
                  setEditingRow({ ...editingRow, role: e.target.value })
                }
                fullWidth
              >
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </TextField>
            );
          } else {
            return <Typography>{row.original.role}</Typography>;
          }
        },
      },
      {
        id: "actions",
        header: "Actions",
        Cell: ({ row }) => (
          <Box>
            {editingRowId === row.original.id ? (
              <>
                <Button color="success" onClick={handleSaveEdit}>
                  Save
                </Button>
                <Button color="error" onClick={handleCancelEdit}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <IconButton onClick={() => handleEditRow(row.original)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleDeleteRow(row.original)}>
                  <Delete />
                </IconButton>
              </>
            )}
          </Box>
        ),
      },
    ],
    [
      editingRow,
      editingRowId,
      handleEditRow,
      handleDeleteRow,
      handleSaveEdit,
      handleCancelEdit,
      roles,
    ]
  );

  return (
    <div>
      <Button
        onClick={handleAddRow}
        startIcon={<Add />}
        disabled={!!newRowData}
        color="success"
      >
        Add New Permission
      </Button>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        state={{ isLoading, showAlertBanner: isError }}
        enableColumnOrdering
        enableColumnFilterModes
        enablePinning
        enableGrouping
        positionToolbarAlertBanner="bottom"
        initialState={{
          showColumnFilters: false,
          sorting: [
            {
              id: "id",
              desc: false,
            },
          ],
        }}
        muiToolbarAlertBannerProps={
          isError
            ? { color: "error", children: "Error loading permissions data" }
            : undefined
        }
        renderTopToolbarCustomActions={() => {
          const handleGoBack = () => {
            navigate(`/admin`);
          };

          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Button
                color="secondary"
                onClick={handleGoBack}
                variant="contained"
              >
                Go Back
              </Button>
            </div>
          );
        }}
      />
      {newRowData && (
        <Box sx={{ marginTop: 2 }}>
          <TextField
            select
            label="Reactor Name"
            value={
              filteredAvailableReactors.length > 0
                ? newRowData.name
                : "None available"
            }
            onChange={(e) =>
              setNewRowData({ ...newRowData, name: e.target.value })
            }
            sx={{ marginRight: 2 }}
          >
            {filteredAvailableReactors.length > 0 ? (
              filteredAvailableReactors.map((reactor) => (
                <MenuItem key={reactor.id} value={reactor.name}>
                  {reactor.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled value="None available">
                None available
              </MenuItem>
            )}
          </TextField>
          <TextField
            select
            label="Role"
            value={newRowData.role}
            onChange={(e) =>
              setNewRowData({ ...newRowData, role: e.target.value })
            }
            sx={{ marginRight: 2 }}
          >
            {filteredAvailableReactors.length > 0 ? (
              roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled value="None">
                None
              </MenuItem>
            )}
          </TextField>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveNewRow}
            sx={{ marginRight: 1 }}
            disabled={filteredAvailableReactors.length === 0}
          >
            Save New Permission
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancelNewRow}
          >
            Cancel
          </Button>
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SnackbarContent
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              color="inherit"
              onClick={() => setSnackbarOpen(false)}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        />
      </Snackbar>
    </div>
  );
};

export default ReactorsPermissionsTable;
