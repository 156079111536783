import React from 'react';
import useAuth from '../hooks/useAuth';
import { toPascalCase } from '../utils/formatting';
import Header from './Header';

function UserProfile() {
    const { auth } = useAuth();
    const username = auth.user || '';
    const formattedUsername = toPascalCase(username)
    const userRoles = auth.roles || [];
    const formattedRoles = userRoles.map(toPascalCase).sort().join(', ');
  return (
    <section>
    <Header
      title="USER PROFILE"
      subtitle="Find here information regarding user"
    />
    <br />
    <div>
      <div>
        <strong>Username:</strong> {formattedUsername}
      </div>
      <div>
        <strong>Roles:</strong> {formattedRoles}
      </div>
    </div>
    <br />
  </section>
  );
}

export default UserProfile;