import UsersTable from "../../components/UsersTable";
import Header from "../../components/Header";

const Admin = () => {
  return (
    <section>
      <Header
        title="USER MANAGEMENT"
        subtitle="Create, Edit and Reset Users"
      />
      <br />
      <UsersTable />
      <br />
    </section>
  );
};

export default Admin;
