import React from "react";
import { Snackbar, SnackbarContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SnackbarAlert = ({ open, message, onClose }) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
    <SnackbarContent
      message={message}
      action={
        <IconButton size="small" color="inherit" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  </Snackbar>
);
export default SnackbarAlert;