import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, ProSidebarProvider, useProSidebar } from 'react-pro-sidebar';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import logoDark from '../../assets/foom.png';
import logoLight from '../../assets/foom_lightmode.png';
import useAuth from '../../hooks/useAuth';
import { tokens } from "../../theme";
import { toPascalCase } from '../../utils/formatting';


const MySidebar = () => {
  const { collapseSidebar } = useProSidebar();
  const { auth } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const username = auth.user || '';
  const formattedUsername = toPascalCase(username);
  const userRoles = auth.roles || [];
  const formattedRoles = userRoles.map(toPascalCase).sort().join(', ');
  const rootStyles = {
    ["&"]: {
      borderRightStyle: "none",
    },
  };


  return (
    <Box style={{backgroundColor: colors.primary[800]}}>
      <Sidebar className="sidebar" style={{ display: "flex", height: '100vh'}} backgroundColor={ colors.primary[400] } rootStyles={rootStyles} >
        <Menu iconShape="square" menuItemStyles={{
      button: {
        [`&.active`]: {
          backgroundColor: colors.grey[600],
          color: colors.grey[100],
        },
      },
    }}>
          {/* Logo and menu icon */}
          <MenuItem
            onClick={() => collapseSidebar()}
            icon={ <MenuOutlinedIcon />}
          >
            { (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">
                  {theme.palette.mode === 'dark' ? (
                    <img src={logoDark} alt="Logo" height="50" />
                  ) : (
                    <img src={logoLight} alt="Logo" height="50" />
                  )}
                </Typography>
                <IconButton onClick={() => collapseSidebar()}>
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* User Profile */}
          {(
            <Box textAlign="center" mb="25px">
              <Typography variant="h6" color="inherit">
                {formattedUsername}
              </Typography>
              <Typography variant="subtitle1" color="inherit">
                {formattedRoles}
              </Typography>
            </Box>
          )}

          {/* Menu Items */}
          <MenuItem icon={<HomeOutlinedIcon />} component={<Link to="/" className="link"/>}>
            Dashboard
          </MenuItem>
          <MenuItem icon={<PeopleOutlinedIcon/>} component={<Link to="/admin" className="link"/>}>
            Manage Users
          </MenuItem>
          <MenuItem icon={<ContactsOutlinedIcon />} component={<Link to="/contacts" className="link"/>}>
            Contacts Information
          </MenuItem>
          <MenuItem icon={<ReceiptOutlinedIcon />} component={<Link to="/invoices" className="link"/>}>
            Invoices Balances
          </MenuItem>
          <MenuItem icon={<HelpOutlineOutlinedIcon />} component={<Link to="/faq" className="link"/>}>
            FAQ Page
          </MenuItem>
          <MenuItem icon={<BarChartOutlinedIcon />} component={<Link to="/bar" className="link"/>}>
            Bar Chart
          </MenuItem>
          <MenuItem icon={<PieChartOutlineOutlinedIcon />} component={<Link to="/pie" className="link"/>}>
            Pie Chart
          </MenuItem>
          <MenuItem icon={<TimelineOutlinedIcon />} component={<Link to="/line" className="link"/>}>
            Line Chart
          </MenuItem>
          <MenuItem icon={<MapOutlinedIcon />} component={<Link to="/geography" className="link"/>}>
            Geography Chart
          </MenuItem>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MySidebar;