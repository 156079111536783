import { axiosPublic } from "../api/axios";
import useAuth from "./useAuth";

/* Logout hook that resets Auth and creates request to logout API */
const useLogout = () => {
  const { setAuth } = useAuth();

  const logout = async () => {
    try {
      const response = await axiosPublic.post(
        "/logout",
        {},
        {
          withCredentials: true,
        }
      );
      setAuth({});
      localStorage.clear()
    } catch (err) {
      console.error(err);
    }
  };
  return logout;
};

export default useLogout;
