import { createContext, useState } from "react";

/* Create Auth object for storing Auth data */
const AuthContext = createContext({});

/* Create Auth component for serving and setting auth props within AuthContext */
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [persist, setPersist] = useState(
    JSON.parse(localStorage.getItem("persist")) || false
  );

  return (
    <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
