import { useLocation, useParams } from 'react-router-dom';
import Header from "../../components/Header";
import ReactorsPermissionsTable from '../../components/ReactorPermissionsTable';

const AdminPermissions = () => {
  const { userId } = useParams();
  const location = useLocation();
  const { userName } = location.state || {};
  const dynamicTitle =`USER PERMISSIONS FOR ${userName}`;
  return (
    <section>
      <Header
        title={dynamicTitle}
        subtitle="Create, Edit and Remove Users' Permissions"
      />
      <br />
      <ReactorsPermissionsTable userId={userId} />
      <br />
    </section>
  );
};

export default AdminPermissions;
